<template>
  <span v-if="item" class="saving-notification d-flex align-center">
    <b>Предложение: </b> {{ item }}
    <slot :saving="saving" :remainder="remainder"> </slot>
  </span>
</template>

<script>
export default {
  props: ['item', 'saving', 'remainder'],
}
</script>

<style scoped>
.saving-notification {
  white-space: pre;
  font-size: 14px;
  padding: 7px;
  border: 1px solid #f2f2f2;
  border-left: 3px solid rgb(234, 157, 62);
  border-radius: 3px;
}
.accept-offer-btn {
  color: #b4a24d;
  font-weight: 500;
  font-size: 0.625rem;
  border-radius: 2px;
  padding: 3px 10px;
}
</style>

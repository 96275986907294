<template>
  <!-- 
    компонент для отображения картинки товара
    с пропсами для списка картинок и главной картинки
   -->
  <div>
    <div class="position-relative">
      <div class="account_img mb-2">
        <div>
          <slot name="main-image" :onSoleClick="onSoleClick">
            <!-- <img :src="baseUrl + selectedColor.photo_path" alt="" /> -->
            <img
              style="position: absolute; top: 0"
              :style="{
                background: `url(${selectedColor}) !important`,
              }"
              v-lazy="
                currentPreviewImage ? baseUrl + currentPreviewImage : images[0]
              "
              :alt="'product'"
              @click="() => onSoleClick(0)"
            />
          </slot>
        </div>
      </div>
      <div class="sale_txt">
        <span class="account_sale">Sale</span>
        <span class="icon_loupe d-flex align-center justify-content-center">
          <v-icon color="#fff" size="15">fas fa-search</v-icon>
        </span>
      </div>
    </div>
    <ul class="product__photo pa-0 row my-0 mx-n2 flex-grow-0">
      <li
        v-for="(img, index) in images && images.slice(1)"
        :key="index"
        class="photo__item col-sm-4 pa-2"
      >
        <div class="photo__item-content">
          <div>
            <img
              @click="() => onImageClick(index + 1)"
              v-lazy="img"
              :alt="'product ' + index + 1"
            />
          </div>
        </div>
      </li>
    </ul>
    <!-- v-if="showModal" -->
    <image-zoom
      v-if="isSole === 'sole'"
      :image="currentPreviewImage"
      :showModal="showModal"
      :color="selectedColor"
      @closeModal="showModal = false"
    />
    <CoolLightBox
      :items="images"
      :index="imageIndex"
      @close="imageIndex = null"
    ></CoolLightBox>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import ImageZoom from '../../content/store/ImageZoom.vue'
export default {
  name: 'ProductImageComponent',
  components: {
    CoolLightBox,
    ImageZoom,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    currentPreviewImage: {
      type: String,
    },
    selectedColor: {
      type: String,
    },
    isSole: String,
  },
  data: () => ({
    imageIndex: null,
    baseUrl: '',
    color: '',
    showModal: false,
  }),
  watch: {},
  methods: {
    onImageClick(index) {
      this.imageIndex = index
    },
    onSoleClick(index) {
      if (this.isSole === 'sole') {
        this.showModal = true
      } else {
        this.imageIndex = index
      }
    },
  },
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
  },
}
</script>

<style lang="scss" scoped>
$gr_col: #818181;
@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

.custom-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Регулируйте высоту по вашему усмотрению */
}

.custom-image {
  max-width: 100%; /* Изображение не будет превышать своего естественного размера */
  max-height: 100%; /* Изображение не будет превышать своего естественного размера */
}
.account_img {
  width: 100%;
  padding-top: 100%;
  background: #ffffff;
  border-radius: 1px;
  position: relative;
  cursor: pointer;
  & > div {
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    object-fit: contain;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
  }

  @media (max-width: 1024px) {
    height: 12rem;
    @media (max-width: 769px) {
      height: 8rem;
      @media (max-width: 481px) {
        height: 10rem;
      }
    }
  }
}

.sale_txt {
  position: absolute;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  padding: 12px;
  cursor: pointer;
  top: 0;

  @media (max-width: 1025px) {
    width: 92%;
  }

  .account_sale {
    @include ftz(0.75rem, 400, #766c64);
    text-transform: capitalize;
    width: 4rem;
    height: 1.5rem;
    background: #e8d36a;
    border-radius: 1px;
    padding: 4px;
    text-align: center;
    @media (max-width: 669px) {
      width: 40px;
    }
  }

  .icon_loupe {
    width: 25px;
    height: 25px;
    background: #c7c7c7;
    border-radius: 1px;
  }
}

.product__photo {
  @media (max-width: 575px) {
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
  }
}
.photo__item {
  height: fit-content;
  @media (max-width: 575px) {
    max-width: 170px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  @media (max-width: 425px) {
    max-width: 120px;
  }
  &-content {
    cursor: pointer;

    padding-top: 100%;
    position: relative;
    border-radius: 1px;
    & > div {
      position: absolute;
      inset: 0;
      background-color: #fff;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }
  }
}
</style>

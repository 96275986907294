<template>
  <div class="image-modal" v-if="showModal" @click="closeModal">
    <div class="close-icon" @click="closeModal">
      <i class="close fas fa-times"></i>
    </div>
    <div
      :style="{
        transform: zoomed ? 'scale(2)' : 'scale(1)',
      }"
      class="image-modal-content"
      @click.stop
    >
      <div class="image-container">
        <img
          :src="baseUrl + image"
          alt="Your Image"
          @click="zoomImage"
          :style="{ background: `url(${color})` }"
          :class="{ zoomed: zoomed }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    index: Number,
    showModal: Boolean,
    color: String,
  },
  data() {
    return {
      zoomed: false,
      baseUrl: '',
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal', false)
      this.zoomed = false
    },
    zoomImage() {
      this.zoomed = !this.zoomed
    },
  },
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
  },
}
</script>

<style scoped lang="scss">
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Полупрозрачный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  margin: auto;
}

.image-modal-content {
  background: rgba(0, 0, 0, 0); /* Полупрозрачный фон */
  max-width: 80%; /* Максимальная ширина контейнера с изображением */
  max-height: 80%; /* Максимальная высота контейнера с изображением */
  overflow: auto; /* Добавление прокрутки, если изображение больше контейнера */
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin: auto;
  }
}

.close {
  text-align: right;
  color: #fff;
  opacity: 1;
  font-size: 35px;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.image-container {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
  max-width: 100%;
  max-height: 100%;
}

.zoomed {
  height: auto;
  margin: auto;
  cursor: zoom-in;
}

.image-container img {
  width: 100%;
  height: 380px;
  margin: auto;
}

.image-container:hover .zoomed {
  cursor: zoom-out;
}
</style>
